<template>
  <div ref="main">
    <select
      ref="year"
      @input="update"
      :value="splitDate.year"
      :disabled="readonly"
    >
      <option :value="'0000'">Tahun</option>
      <option
        v-for="(field, id) in cal.years"
        :key="id"
        :value="field"
        v-text="field"
      ></option>
    </select>
    <select
      ref="month"
      @input="update"
      :value="splitDate.month"
      :disabled="readonly"
    >
      <option :value="'00'">Bulan</option>
      <option
        v-for="(field, id) in cal.months"
        :key="id"
        :value="field.value"
        v-text="field.name"
      ></option>
    </select>
    <select
      ref="date"
      @input="update"
      :value="splitDate.date"
      :disabled="readonly || cal.dates.length == 0"
    >
      <option :value="'00'">Tanggal</option>
      <option
        v-for="(field, id) in cal.dates"
        :key="id"
        :value="field"
        v-text="field"
      ></option>
    </select>
  </div>
</template>

<script>
import config from "../config";
export default {
  props: ["value", "readonly"],
  data() {
    return {
      cal: {
        dates: [],
        months: [
          { value: "01", name: "Januari" },
          { value: "02", name: "Februari" },
          { value: "03", name: "Maret" },
          { value: "04", name: "April" },
          { value: "05", name: "Mei" },
          { value: "06", name: "Juni" },
          { value: "07", name: "Juli" },
          { value: "08", name: "Agustus" },
          { value: "09", name: "September" },
          { value: "10", name: "Oktober" },
          { value: "11", name: "November" },
          { value: "12", name: "Desember" },
        ],
        years: [],
      },
    };
  },
  methods: {
    update() {
      const monthValue = this.$refs.month.value;
      const yearValue = this.$refs.year.value;
      const dateValue = this.$refs.date.value;
      this.$emit("input", `${yearValue}-${monthValue}-${dateValue}`);
    },
  },
  computed: {
    splitDate() {
      if (this.value) {
        const splitValueString = this.value.split("-");

        if (splitValueString[0] != "0000" && splitValueString[1] != "00") {
          var date = new Date(
            splitValueString[0],
            splitValueString[1],
            0
          ).getDate();
          var dates = [];
          for (var k = 1; k <= date; k++) dates.push(("0" + k).slice(-2));
          this.cal.dates = dates;
        }

        return {
          year: splitValueString[0],
          month: splitValueString[1],
          date: splitValueString[2],
        };
      } else {
        return {
          year: "0000",
          month: "00",
          date: "00",
        };
      }
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          if (this.value.split("-")[2]) {
            setTimeout(() => {
              this.$refs.date.value = this.value.split("-")[2];
            }, 0);
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    var range = config.app.tanggalLahirRange;
    for (var start = range[0]; start <= range[1]; start++) {
      this.cal.years.push(start);
    }

    let date = 28; // default tanggal
    let dates = [];
    for (var k = 1; k <= date; k++) dates.push(("0" + k).slice(-2));
    this.cal.dates = dates;

    var padding = getComputedStyle(this.$refs.main).padding;
    var color = getComputedStyle(this.$refs.main).color;

    this.$refs.main.style.padding = 0;
    this.$refs.date.style.padding = padding;
    this.$refs.month.style.padding = padding;
    this.$refs.year.style.padding = padding;
    this.$refs.date.style.color = color;
    this.$refs.month.style.color = color;
    this.$refs.year.style.color = color;
  },
};
</script>

<style scoped>
select {
  border: none;
  padding: 4px 8px;
}
select:disabled {
  background-color: #e9ecef;
}

.main-wrapper-dark select {
  background: #202020;
}

.main-wrapper-dark select:disabled {
  background-color: #202020;
}
</style>
