<template>
  <div>
    <div class="card border-bottom-0 mb-3 mt-4">
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <h6 class="mb-0">
          <fa-icon :icon="['fa', 'users']" class="mr-3 text-secondary" />Orang
          Tua &amp; Mahrom
        </h6>
        <div>
          <button
            @click="tambahTrigger"
            :disabled="keluarga.length > 0 ? keluarga[0].id === true : false"
            class="btn btn-sm btn-primary"
          >
            <fa-icon :icon="['fa', 'plus']" class="mr-2" />Tambah
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div
          class="alert alert-warning"
          role="alert"
          v-if="
            biodatas.jenis_kelamin == 'P' &&
            biodatas.pesertadidik.is_mondok == 'Y'
          "
        >
          <i class="text-muted"
            >Lengkapi juga data mahrom, diperuntukkan jika keluarga mahrom ingin
            mengunjungi ke pondok di kemudian hari. <br />Diantara keluarga
            mahrom seperti, saudara kandung/tiri, paman, bibi, kakek atau nenek
            dari pesertadidik, dll. <br /><b
              >Jika mahrom terdaftar di lain KK, maka wajib menyertakan &amp;
              mengupload KK dari mahrom.</b
            >
          </i>
        </div>

        <div v-for="(person, id) in keluarga" :key="id">
          <person
            :person="person"
            :biodatas="biodatas"
            @refreshBio="getKeluarga"
          />
        </div>

        <div
          class="alert alert-danger"
          role="alert"
          v-if="keluarga.length == 0"
        >
          Tidak Terdapat Keluarga
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Person from "./Person";
export default {
  components: { Person },
  props: ["biodatas"],
  data() {
    return {
      keluarga: [],
    };
  },
  methods: {
    tambahTrigger() {
      this.keluarga.unshift({
        id: true,
        warganegara: "WNI",
        id_negara: null,
        id_provinsi: null,
        id_kabupaten: null,
        id_kecamatan: null,

        id_pekerjaan: null,
        id_penghasilan: null,
        relasi_keluarga: {
          kode_relasi: null,
          sebagai_wali: "T",
        },
      });
    },
    getKeluarga() {
      if (!this.biodatas.uuid) return;
      this.$store.commit("setLoading", true);
      // this.$topprogressbar.start();
      this.$axios
        .get("/pendaftar/" + this.biodatas.uuid + "/keluarga")
        .then((res) => {
          this.$store.commit("setLoading", false);
          this.keluarga = res.data;
          // setTimeout(() => this.$topprogressbar.finish(), 500);
        })
        .catch(() => {
          // this.$topprogressbar.fail();
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
  },

  watch: {
    biodatas: {
      handler() {
        this.getKeluarga();
      },
      deep: true,
    },
  },
  mounted() {
    this.getKeluarga();
  },
};
</script>
